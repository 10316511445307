import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Config from "../../config/app.config.json";
import EnvConfig from "../../config/env.config.json";
import firebase from "../../adapters/firebase";
import { NotificationCust } from "../widgets";
import { useNavigate } from "react-router-dom";
import {
  emailPattern,
  namePattern,
  numberInStringPattern,
} from "../../utils/regexPatterns";
import { useFormik } from "formik";
import { string, object, boolean } from "yup";
import { strapiSignUpContent } from "../../features/strapi/signupSlice";
import { getInTouchApi } from "../../features/homepage/homepageSlice";
import { updateToHubspot } from "../../utils/commonFunctions/hubspot";
import { RoutingURLs } from "../../config/RoutingURLs";
import { setLoginStatus } from "../../features/checkout/checkoutSlice";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../utils/commonFunctions/addMultipleCacheData";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import { isEmpty } from "lodash";
import { gtmTagManager } from "../../utils/commonFunctions/GtmTagManager";
import FirebaseDBURLs from "../../config/FirebaseDBURLs";
import { shopwareAuthentication } from "../../utils/commonFunctions/shopWareFunctions";
import {
  getCartItems,
  shopwareAuth,
} from "../../features/shopWare/shopWareSlice";
import Cookies from "js-cookie";
const SignUpTemplate = isTemplateExisits(Config.SignUp.SignUp)
  ? require(`./${Config.SignUp.SignUp.template}_SignUp`).default
  : null;

export type SignUpProps = {
  content: any;
  onAppleSignUp: any;
  onFacebookSignUp: any;
  onGoogleSignUp: any;
  isSignUpClicked: boolean;
  formik: any;
  handleFormikChange: any;
  showLogin: any;
  formSubmit: any;
};

interface AuthUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  checked: boolean;
}

type Props = {
  isCheckout?: boolean;
  toggleForm?: any;
  commonSocialRedirect?: any;
};

export const SignUpComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { shopwareAuthSuccess, shopwareAuthError } = useAppSelector(
    (state: any) => state.shopWare
  );
  const { getInTouch } = useAppSelector((state: any) => state.homepage);
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.signup,
      category: category,
      buttonValue: value,
    });
  };

  const { signUpContent } = useAppSelector(
    (state: any) => state.strapi.signupPage
  );
  const { isCheckout, toggleForm, commonSocialRedirect } = props;

  const [cacheSignUpContent, setCacheSignUpContent] = useState<any>(null);
  const [isSignUpClicked, setIsSignUpClicked] = useState(true);

  useEffect(() => {
    getCache(
      "signUpComponent",
      strapiSignUpContent,
      setCacheSignUpContent,
      dispatch
    );
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      let searchParams = new URLSearchParams(document.location.search);
      let logoutCheck: string | null = searchParams.get("logout");
      if (!logoutCheck) {
        dispatch(getCartItems());
      }
    }
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("signUpComponent", signUpContent);
  }, [signUpContent]);

  useNonInitialEffect(() => {
    if (shopwareAuthSuccess) {
      let currentUser: any = localStorage.getItem("currentuser");
      currentUser = JSON.parse(currentUser);
      let swContextToken: any = shopwareAuthSuccess["sw-context-token"];
      Cookies.set("swContextToken", `${swContextToken}`);
      let searchParams = new URLSearchParams(document.location.search);
      let idTokenValue: any = localStorage.getItem("accessToken");
      let Redirect_Url: string | null = searchParams.get("redirect_url");
      dispatch(setLoginStatus());
      NotificationCust({
        message: notification && notification.signUpSuccess,
        duration: 3000,
        id: "SignUpNotification",
      });
      if (Redirect_Url) {
        window.location.assign(
          `${Redirect_Url}?token=${swContextToken}&firebase_token=${idTokenValue}&logged_in=${
            currentUser && currentUser.email ? true : false
          }`
        );
      } else {
        if (isCheckout) {
          let user1: any = localStorage.getItem("currentuser");
          user1 = JSON.parse(user1);
          props.commonSocialRedirect(user1, "Email");
        } else {
          navigate(RoutingURLs.home);
        }
      }
    }
  }, [shopwareAuthSuccess]);

  useNonInitialEffect(() => {
    if (isCheckout) {
      let user1: any = localStorage.getItem("currentuser");
      user1 = JSON.parse(user1);
      props.commonSocialRedirect(user1, "Email");
    } else {
      navigate(RoutingURLs.home);
    }
  }, [shopwareAuthError]);

  const initialAuthUserData: AuthUser = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    checked: false,
  };

  useEffect(() => {
    // !isCheckout && localStorage.clear();
    // return () => {
    //   Store.removeNotification("SignUpErrorNotification");
    //   Store.removeNotification("SignUpNotification");
    // };
  }, []);

  const { signUpErrors, notification, validation_errors } = cacheSignUpContent
    ? cacheSignUpContent
    : signUpContent || {};

  const showErrorMessage = (code: any) => {
    let errorMsg = "";
    switch (code) {
      case "auth/email-already-in-use":
        errorMsg = signUpErrors["signup"][code];
        break;
      case "auth/invalid-email":
        errorMsg = signUpErrors["signup"][code];
        break;
      case "auth/operation-not-allowed":
        errorMsg = signUpErrors["signup"][code];
        break;
      case "auth/weak-password":
        errorMsg = signUpErrors["signup"][code];
        break;
      case "auth/invalid-email":
        errorMsg = signUpErrors["login"][code];
        break;
      case "auth/user-disabled":
        errorMsg = BrandNameUpdate(signUpErrors["login"][code] || "");
        break;
      case "auth/user-not-found":
        errorMsg = signUpErrors["login"][code];
        break;
      case "auth/wrong-password":
        errorMsg = signUpErrors["login"][code];
        break;
      case "auth/account-exists-with-different-credential":
        errorMsg = signUpErrors["social_login"][code];
        break;
      case "auth/auth-domain-config-required":
        errorMsg = signUpErrors["social_login"][code];
        break;
      case "auth/cancelled-popup-request":
        errorMsg = "";
        break;
      case "auth/operation-not-supported-in-this-environment":
        errorMsg = signUpErrors["social_login"][code];
        break;
      case "auth/popup-blocked":
        errorMsg = signUpErrors["social_login"][code];
        break;
      case "auth/popup-closed-by-user":
        errorMsg = "";
        break;
      case "auth/unauthorized-domain":
        errorMsg = signUpErrors["social_login"][code];
        break;
      case "auth/user-cancelled":
        errorMsg = "";
        break;
      default:
        errorMsg = signUpErrors["default"];
        break;
    }

    if (errorMsg) {
      NotificationCust({
        message: errorMsg,
        type: "danger",
        duration: 5000,
        id: "SignUpErrorNotification",
        showIcon: true,
      });
    }
  };

  const onGoogleSignUp = () => {
    GAAndFBEventsHandler(gaCategory.signup, "Google Signup");
    gtmTagManager({
      event: gaScreenNames.signup,
      signup_method: "Google Signup",
    });
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        localStorage.setItem("currentuser", JSON.stringify(result.user));
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        if (user1 && user1.email) {
          checkWith4GUser(user1.email, token);
        }
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "google", notification?.signUpSuccess);
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const onAppleSignUp = () => {
    GAAndFBEventsHandler(gaCategory.signup, "Apple Signup");
    gtmTagManager({
      event: gaScreenNames.signup,
      signup_method: "Apple Signup",
    });
    let provider = new firebase.auth.OAuthProvider("apple.com");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        localStorage.setItem("currentuser", JSON.stringify(result.user));
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        if (user1 && user1.email) {
          checkWith4GUser(user1.email, token);
        }
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(user1, "apple", notification?.signUpSuccess);
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const onFacebookSignUp = () => {
    GAAndFBEventsHandler(gaCategory.signup, "Facebook Signup");
    gtmTagManager({
      event: gaScreenNames.signup,
      signup_method: "Facebook Signup",
    });
    let provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async () => {
        let user1: any = localStorage.getItem("currentuser");
        user1 = JSON.parse(user1);
        let token =
          user1 &&
          user1.uid &&
          user1.stsTokenManager &&
          user1.stsTokenManager.accessToken;
        localStorage.setItem("accessToken", token);
        if (user1 && user1.email) {
          checkWith4GUser(user1.email, token);
        }
        isCheckout &&
          setTimeout(() => {
            commonSocialRedirect(
              user1,
              "facebook",
              notification?.signUpSuccess
            );
          }, 2000);
      })
      .catch((error) => {
        showErrorMessage(error.code);
      });
  };

  const checkWith4GUser = (email: string, token: any) => {
    fetch(
      `https://api.reachmobile.com/v0/customers/username?id=${encodeURIComponent(
        email
      )}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((_data) => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            localStorage.removeItem("currentuser");
            localStorage.removeItem("accessToken");
            localStorage.clear();
            localStorage.setItem("globalValues", JSON.stringify(globalVal));
          });
        window.open(`${EnvConfig.REDIRECT_URL}`, "_self");
      })
      .catch((_error) => {
        firebase
          .database()
          .ref(FirebaseDBURLs.source + window.btoa(email))
          .once("value")
          .then((snapshot: any) => {
            let source = snapshot.val();
            if (source && source == EnvConfig.OPERATOR) {
              !isCheckout && navigate(RoutingURLs.home);
            } else {
              NotificationCust({
                message: notification && notification.signUpSuccess,
                duration: 3000,
                id: "SignUpNotification",
              });
              updateSourceToFirebase(email);
            }
          });
      });
  };
  const updateSourceToFirebase = async (email: any) => {
    await firebase
      .database()
      .ref(FirebaseDBURLs.source)
      .update({ [window.btoa(email)]: EnvConfig.OPERATOR }, (error) => {
        if (error) {
          console.error("source update fail.");
        } else {
          console.error("source update success.");
        }
      });
  };
  const {
    invalidFname,
    emptyFname,
    invalidLname,
    emptyLname,
    emptyEmail,
    invalidEmail,
    emptyPassword,
    invalidPassword,
    termsError,
  } = validation_errors || {};

  const signUpSchema = object({
    firstName: string()
      .trim()
      .min(2, invalidFname)
      .max(24, invalidFname)
      .required(emptyFname)
      .matches(namePattern, invalidFname),
    lastName: string()
      .trim()
      .min(2, invalidLname)
      .max(24, invalidLname)
      .required(emptyLname)
      .matches(namePattern, invalidLname),
    email: string()
      .trim()
      .required(emptyEmail)
      .matches(emailPattern, invalidEmail),
    password: string().trim().required(emptyPassword).min(8, invalidPassword),
    checked: boolean().required().oneOf([true], termsError),
  });

  const formik = useFormik({
    initialValues: initialAuthUserData,
    validationSchema: signUpSchema,
    onSubmit: async (values: AuthUser) => {
      const { firstName, lastName, email, password } = values;

      password.match(numberInStringPattern) && setIsSignUpClicked(false);
      password.match(numberInStringPattern) &&
        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then((user) => {
            const user1 = firebase.auth().currentUser;
            if (user1) {
              user1
                .updateProfile({
                  displayName: `${firstName.trim()},${lastName.trim()}`,
                })
                .then(async () => {
                  if (!EnvConfig?.IS_SHOPWARE_ENABLED) {
                    dispatch(setLoginStatus());
                  }
                  localStorage.setItem(
                    "currentuser",
                    JSON.stringify(user && user.user)
                  );
                  let user1: any = localStorage.getItem("currentuser");
                  user1 = JSON.parse(user1);
                  let token =
                    user1 &&
                    user1.uid &&
                    user1.stsTokenManager &&
                    user1.stsTokenManager.accessToken;
                  localStorage.setItem("accessToken", token);
                  if (!EnvConfig?.IS_SHOPWARE_ENABLED) {
                    NotificationCust({
                      message: notification && notification.signUpSuccess,
                      duration: 3000,
                      id: "SignUpNotification",
                    });
                  }
                  if (EnvConfig?.IS_SHOPWARE_ENABLED) {
                    isCheckout && shopwareAuthHandler();
                  } else {
                    isCheckout && props.commonSocialRedirect(user1, "Email");
                  }
                  updateSourceToFirebase(user1.email);
                  !isCheckout &&
                    setTimeout(() => {
                      if (EnvConfig?.IS_SHOPWARE_ENABLED) {
                        shopwareAuthHandler();
                      } else {
                        navigate(RoutingURLs.home);
                      }
                    }, 2000);
                })
                .catch((error) => {
                  setIsSignUpClicked(true);
                  showErrorMessage(error.code);
                });
            }
          })
          .catch((error) => {
            setIsSignUpClicked(true);
            showErrorMessage(error.code);
          });
    },
    enableReinitialize: true,
  });

  const shopwareAuthHandler = () => {
    let payloadData: any = shopwareAuthentication();
    if (payloadData) {
      dispatch(shopwareAuth(payloadData));
    }
  };

  const handleFormikChange = (e: any) => {
    formik.handleChange(e);
    formik.setTouched({
      ...formik.touched,
      [e.target.name]: false,
    });
  };

  const showLogin = () => {
    if (isCheckout) return toggleForm();
    else if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      let searchParams = new URLSearchParams(document.location.search);
      let Redirect_Url: string | null = searchParams.get("redirect_url");
      if (Redirect_Url) {
        navigate(`${RoutingURLs.login}?redirect_url=${Redirect_Url}`);
      } else {
        navigate(RoutingURLs.login);
      }
    } else {
      navigate(RoutingURLs.login);
    }
  };

  const formSubmit = (e: any) => {
    e.preventDefault();
    const { email } = formik.values;
    if (emailPattern.test(email)) {
      updateToHubspot(e, email);
      dispatch(
        getInTouchApi({
          data: {
            emailId: email,
            hubspotMap: {
              email: email,
              is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
              is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
              // brand_name: globalVal?.short_name?.toLowerCase(), //brand name is not being used in hubspot this property is overriding hubsport config.
              website_journey_score: 1,
              website_logged_in: true,
              website_login_date: new Date().getTime(),
            },
          },
        })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          localStorage.setItem("currentuserEmailId", email);
        }
      });
    }
    formik.handleSubmit(e);
  };

  return (
    (cacheSignUpContent || signUpContent) && (
      <SignUpTemplate
        content={cacheSignUpContent ? cacheSignUpContent : signUpContent}
        onAppleSignUp={onAppleSignUp}
        onFacebookSignUp={onFacebookSignUp}
        onGoogleSignUp={onGoogleSignUp}
        formik={formik}
        isSignUpClicked={isSignUpClicked}
        handleFormikChange={handleFormikChange}
        showLogin={showLogin}
        formSubmit={formSubmit}
      />
    )
  );
};
